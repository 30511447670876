import { createSlice } from '@reduxjs/toolkit';
import { Map } from 'immutable';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { createSelector } from 'reselect';
import { makeSelectCountry, makeSelectLocale } from '@dbh/routing-redux';
import { createSimpleSelector, useMakeSelect } from '@dbh/reselect-extra';

// is always injected in server side rendering. @see `@dbh/create-reducer-redux`.
const REDUX_AND_SAGA_KEY="dateFnsLocales";const initialState=Map({dateFnsLocales:Map()});

const dateFnsLocalesSlice=createSlice({name:REDUX_AND_SAGA_KEY,initialState,reducers:{fnsLocaleDataLoaded:(a,b)=>{var c;const{country:d,locale:e,dateFnsLocale:f}=b.payload;return a={...a,dateFnsLocales:{...a.dateFnsLocales,[d]:{...(null==(c=a.dateFnsLocales)?void 0:c[d]),[e]:f}}},a}}});const {fnsLocaleDataLoaded}=dateFnsLocalesSlice.actions;var reducer = dateFnsLocalesSlice.reducer;

/**
 * @typedef {import('date-fns').Locale} Locale
 *//**
 * Returns the `date-fns` locale from the given app locales.
 * @param {string} locale @example `en-GB`, `it-IT`.
 * @return {Promise<Locale?>} .
 */const getDateFnsLocaleData=withConformsTo("getDateFnsLocaleData",[],async a=>{let b;// @see `getBabelConfig.js`, `webpack.browser.base.js`
try{const{default:c}=await import(/* webpackChunkName: "date-fns" */"date-fns/locale/"+a+"/index.js");b=c;}catch(c){const[d]=a.split("-"),{default:e}=await import(/* webpackChunkName: "date-fns" */"date-fns/locale/"+d+"/index.js");// Fallback in case our locale is not supported by `date-fns`.
b=e;}return b});var getDateFnsLocaleData$1 = getDateFnsLocaleData;

/**
 * Returns the slice of the state that contains the `dateFnsLocales` state.
 * @param {Object} state `redux` state.
 * @return {Object?} .
 */const selectDateFnsLocales=a=>a[REDUX_AND_SAGA_KEY];var selectDateFnsLocales$1 = selectDateFnsLocales;

/**
 * Returns a selector that returns all the `date-fns` locales data.
 * @return {Immutable.Map} The `date-fns` locale data.
 */const makeSelectAllDateFnsLocales=()=>createSimpleSelector(selectDateFnsLocales$1,["dateFnsLocales"]);var makeSelectAllDateFnsLocales$1 = makeSelectAllDateFnsLocales;

/**
 * @typedef {import('date-fns').Locale} Locale
 *//**
 * Returns a selector that returns the current `date-fns` locale data.
 * @return {Locale?} .
 */const makeSelectCurrentDateFnsLocaleData=()=>createSelector(makeSelectCountry(),makeSelectLocale(),makeSelectAllDateFnsLocales$1(),(a,b,c)=>{var d;const e=null==c||null==(d=c[a])?void 0:d[b];return e});var makeSelectCurrentDateFnsLocaleData$1 = makeSelectCurrentDateFnsLocaleData;

/**
 * @typedef {import('date-fns').Locale} Locale
 *//**
 * Returns the current `date-fns` locale data.
 * @return {Locale?} .
 */const useCurrentDateFnsLocaleData=()=>{const a=useMakeSelect(makeSelectCurrentDateFnsLocaleData$1);return a};var useCurrentDateFnsLocaleData$1 = useCurrentDateFnsLocaleData;

const importDateFnsLocalesSagas=import('./index-af41cb81.js'/* webpackChunkName: 'dbh.date-fns-locales-sagas' */);

export { REDUX_AND_SAGA_KEY, reducer as dateFnsLocalesReducer, fnsLocaleDataLoaded, getDateFnsLocaleData$1 as getDateFnsLocaleData, importDateFnsLocalesSagas, initialState, makeSelectCurrentDateFnsLocaleData$1 as makeSelectCurrentDateFnsLocaleData, useCurrentDateFnsLocaleData$1 as useCurrentDateFnsLocaleData };
